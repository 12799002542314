import ClearIcon from '@mui/icons-material/Clear'
import Modal from '@mui/material/Modal'
import { isAxiosError } from 'axios'
import QRCode from 'qrcode'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLoaderData } from 'react-router-dom'

import { selectActiveCompany } from '../../companies/activeCompanyIdReducer'
import { confirmBankAuthentication, initiateBankAuthentication } from '../dashboardAPI'
import Loader from '../../../components/Loader'

const BankAuthenticationComponent = () => {
  const activeCompany = useSelector(selectActiveCompany)
  const currentEmployee = useLoaderData()
  const [isOpen, setIsOpen] = useState(true)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [qr, setQr] = useState(null)

  useEffect(() => {
    setIsOpen(true)
  }, [activeCompany?.id])

  const handleInitiation = useCallback(async (method) => {
    setIsLoading(true)
    setIsError(false)
    try {
      const { data: { consent_id: consentId } } = await initiateBankAuthentication(method)

      while (true) {
        const { data } = await confirmBankAuthentication(consentId)
        if (data.status === "received") {
          if (data.challenge.data) {
            if (method === "same_device") {
              const url = encodeURIComponent(`${window.location.protocol}//${window.location.host}/?consent_id=${consentId}`)
              window.location.href = `https://app.bankid.com/?autostarttoken=${data.challenge.data}&redirect=${url}`
              break
            } else if (method === "other_device") {
              setIsLoading(false)
              setQr(data.challenge.type === "data" ? await QRCode.toDataURL(data.challenge.data) : data.challenge.data)
              await new Promise((fulfill) => {
                setTimeout(fulfill, 500)
              })
            }
          }
        } else {
          if (data.status === "valid") {
            setIsOpen(false)
          } else {
            setIsError(true)
            setQr(null)
          }
          break
        }
      }
    } catch (error) {
      if (isAxiosError(error)) {
        setIsLoading(false)
        setIsError(true)
        setQr(null)
      } else {
        throw error
      }
    }
  }, [])

  return activeCompany && (
    <Modal disableAutoFocus={true} disableEnforceFocus={true} open={isOpen && activeCompany.bank !== "other" && !activeCompany.open_payments_consent && !!currentEmployee.personal_identity_number}>
      <div className="flex h-full items-center justify-center p-4 w-full">
        <div className="bg-white max-w-lg overflow-y-auto p-8 relative shadow" style={{maxHeight: "calc(100vh - 2rem)"}}>
          <div className="mb-4">För att kunna använda Ekonomimolnet behöver du ansluta till din bank med BankID.</div>
          {!isLoading && !qr && (
            <>
              <ClearIcon className="absolute cursor-pointer right-2 top-2" onClick={() => setIsOpen(false)} />
              <div className="flex flex-wrap justify-center">
                <button
                  className="bg-emerald-500 text-white py-4 px-4 cursor-pointer"
                  onClick={() => handleInitiation("same_device")}
                >
                  Öppna BankID på denna enhet
                </button>
                <button
                  className="bg-emerald-500 mt-4 text-white py-4 px-4 cursor-pointer"
                  onClick={() => handleInitiation("other_device")}
                >
                  Använd BankID på en annan enhet
                </button>
              </div>
            </>
          )}
          {isLoading && (
            <div className="flex justify-center w-full">
              <Loader size={50} style={{}} />
            </div>
          )}
          {qr && (
            <>
              <div className="flex flex-col items-center w-full">
                <img alt="QR" className="h-48 w-48" src={qr} />
                <div className="mt-2 text-center text-sm">Öppna BankID och scanna QR-koden.</div>
              </div>
            </>
          )}
          {isError && <div className="mt-4">Något gick fel. Prova igen.</div>}
        </div>
      </div>
    </Modal>
  )
}

export default BankAuthenticationComponent
